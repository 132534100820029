import '@koncert/styles';
import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import './app.scss';
import 'react-toastify/dist/ReactToastify.css';
import Auth0ProviderWithHistory from './auth/Auth0ProviderWithHistory';
import AuthenticationProvider from './auth/AuthenticationProvider';
import Routes from './Routes';
import configureStore from './store/store';
import './vendor';

const store = configureStore();
const App = () => {
  return (
    <Provider store={store}>
      <Router>
        <Auth0ProviderWithHistory>
          <AuthenticationProvider>
            <Routes />
          </AuthenticationProvider>
        </Auth0ProviderWithHistory>
      </Router>
    </Provider>
  );
};

export default App;
