/**
 * @author @rajesh-thiyagarajan
 * @version V11.0
 */

import React from 'react';
import 'spinkit/spinkit.min.css';
// See more loading icons here:
// https://fontawesome.com/how-to-use/on-the-web/styling/animating-icons
export const PageLoader = () => (
  <div className="page-loader">
    <div className="sk-cube-grid">
      <div className="sk-cube sk-cube1"></div>
      <div className="sk-cube sk-cube2"></div>
      <div className="sk-cube sk-cube3"></div>
      <div className="sk-cube sk-cube4"></div>
      <div className="sk-cube sk-cube5"></div>
      <div className="sk-cube sk-cube6"></div>
      <div className="sk-cube sk-cube7"></div>
      <div className="sk-cube sk-cube8"></div>
      <div className="sk-cube sk-cube9"></div>
    </div>
  </div>
);

export default PageLoader;
