/**
 * @author @rajesh-thiyagarajan
 * @version V11.0
 */
import React from 'react';
import Header from './Header';
import Sidebar from './Sidebar';
import Footer from './Footer';

const Base = ({
  productName,
  children,
}: {
  productName: string;
  children: React.ReactNode;
}) => {
  return (
    <div className="wrapper">
      <Header />
      <Sidebar />
      <section className="section-container">{children}</section>
      <Footer productName={productName} />
    </div>
  );
};
export default Base;
