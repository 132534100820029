/**
 * @author @rajesh-thiyagarajan
 * @version V11.0
 */
const Menu = [
  {
    name: 'Clients',
    icon: 'fas fa-users',
    path: '/clients',
  },
  {
    name: 'Client Users',
    icon: 'fas fa-user-friends',
    path: '/client-users',
  },
  {
    name: 'Orders',
    icon: 'fas fa-shopping-cart',
    path: '/orders',
  },
  // {
  //   name: 'Order Creation',
  //   icon: 'fas fa-cart-plus',
  //   path: '/order-creation',
  // },
  {
    name: 'Invoices',
    icon: 'fas fa-file-invoice',
    path: '/invoices',
  },
  // {
  //   name: 'Audit Trail',
  //   icon: 'far fa-clipboard',
  //   path: '/audit-trail',
  // },
  // {
  //   name: 'User Assignment',
  //   icon: 'fas fa-user-plus',
  //   path: '/user-assignment',
  // },
  // {
  //   name: 'Licenses',
  //   icon: 'fas fa-award',
  //   path: '/licenses',
  // },

  {
    name: 'Settings',
    icon: 'fas fa-cog',
    path: '/settings',
  },
  {
    name: 'Reports',
    icon: 'fas fa-chart-bar',
    path: '/reports',
  },
];

export default Menu;
