/**
 * @author @rajesh-thiyagarajan
 * @version V11.0
 */
import React, { useContext } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Badge } from 'reactstrap';
import UserContext from '../../UserContext';
import Menu from './Menu';

/** Normal items for the sidebar */
const SidebarItem = ({
  item,
  isActive,
  settings,
}: {
  isActive?: boolean;
  settings: {
    isCollapsed: boolean;
  };
  item: any;
}) => (
  <li className={isActive ? 'active' : ''}>
    <Link to={item.path} title={item.title || item.name}>
      {item.label && (
        <Badge
          tag="div"
          className={
            settings.isCollapsed
              ? 'float-right mt-n2 bg-gradient-brand'
              : 'float-right bg-gradient-brand'
          }
          color={item.label.color}
        >
          {item.label.value}
        </Badge>
      )}
      {item.icon && <em className={item.icon}></em>}
      <span>
        {/* <Trans i18nKey={item.translate}>{item.name}</Trans> */}
        {item.name}
      </span>
    </Link>
  </li>
);

const Sidebar = (props: any) => {
  const routeActive = (paths: any) => {
    paths = Array.isArray(paths) ? paths : [paths];
    return paths.some((p: string) => props.location.pathname.indexOf(p) > -1);
  };

  const user = useContext(UserContext);
  const roles = user?.user?.role || [];

  const sideMenus =
    roles.indexOf('ADM') > -1 || roles.indexOf('CMT') > -1
      ? Menu
      : roles.indexOf('CST') > -1
      ? Menu.filter((data) => ['Settings', 'Reports'].indexOf(data.name) === -1)
      : Menu.filter((data) => ['Settings'].indexOf(data.name) === -1);

  return (
    <aside className="aside-container">
      {/* START Sidebar (left) */}
      <div className="aside-inner">
        <div className="d-flex align-items-center">
          <a className="text-decoration-none navbar-brand text-left" href="/">
            <div className="d-flex align-items-center">
              <img className="mr-2" alt="Logo" height="44" />
              <span className="text-sans-serif text-color-koncert-white h3 mb-0">
                License Manager
              </span>
            </div>
          </a>
        </div>
        <nav className="sidebar">
          {/* START sidebar nav */}
          <ul className="sidebar-nav">
            {/* Iterates over all sidebar items */}
            {sideMenus.map((item, i) => {
              return (
                <SidebarItem
                  isActive={routeActive(item.path)}
                  item={item}
                  key={i}
                  settings={props.settings}
                />
              );
            })}
          </ul>
          {/* END sidebar nav */}
        </nav>
      </div>
      {/* END Sidebar (left) */}
    </aside>
  );
};
export default withRouter(Sidebar);
