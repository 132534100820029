import axios from 'axios';
export const GET_ALL_ROLES = 'GET_ALL_ROLES';
export const SET_ALL_ROLES = 'SET_ALL_ROLES';
export const RESET_ALL_ROLES = 'RESET_ALL_ROLES';

export const getRoles = (limit = 10, offset = 0) => {
  const requestData = {
    'page[limit]': limit,
    'page[offset]': offset,
    'sort[roleName]': 'asc',
  };

  return axios({
    url: 'roles',
    params: requestData,
  });
};

export const resetRoles = () => {
  return {
    type: RESET_ALL_ROLES,
    payLoad: {
      fetchedAll: false,
      data: [],
    },
  };
};

const setRoles = (dispatch: any, data: any) => {
  dispatch({
    type: SET_ALL_ROLES,
    payLoad: {
      ...data,
      fetchedAll: true,
      loading: false,
    },
  });
};

export const getAllRoles = () => (dispatch: any) => {
  const limit = 500;
  let offset = 0;
  let accountsData: any = {};

  dispatch({
    type: GET_ALL_ROLES,
    payLoad: {
      loading: true,
    },
  });

  getRoles(limit, offset).then((response) => {
    accountsData = { data: [...response.data.data] };

    setRoles(dispatch, accountsData);
  });
};
