/**
 * @author @rajesh-thiyagarajan
 * @version V11.0
 */
import React from 'react';

export const ApiUrlAndTokenContext = React.createContext({});

const ApiUrlAndTokenProvider = ({
  apiURL,
  token,
  children,
  client,
}: {
  apiURL: string;
  token: string | undefined;
  children: React.ReactNode;
  client: any;
}) => {
  return (
    <ApiUrlAndTokenContext.Provider value={{ apiURL, token, client }}>
      {children}
    </ApiUrlAndTokenContext.Provider>
  );
};

export default ApiUrlAndTokenProvider;
