import React from 'react';

import './bootstrap.scss';
import './app.scss';

/* eslint-disable-next-line */
export interface StylesProps {}

export const Styles = (props: StylesProps) => {
  return (
    <div>
      <h1>Welcome to styles!</h1>
    </div>
  );
};

export default Styles;
