/**
 * @author @rkrishna-gembrill
 * @since Jun 22 2020
 * @version V11.0
 */
import React from 'react';
import {
  Button,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap';

type Props = {
  confirmBtnColor?: 'primary' | 'danger';
  confirmBtnText?: string;
  confirmBtnIcon?: string;
  handleCancel: () => void;
  handleConfirm: () => void;
  header?: string;
  modalSize?: 'sm' | 'md' | 'lg' | 'xl';
  showConfirmBtnSpinner?: boolean;
  disableConfirmBtn?: boolean;
  showConfirmModal: boolean;
  bodyStyle?: string;
};

const ConfirmModal: React.FC<Props> = ({
  children,
  confirmBtnColor = 'primary',
  confirmBtnText = 'Confirm',
  confirmBtnIcon = 'fas fa-check',
  handleConfirm,
  handleCancel,
  header = 'Please Confirm!',
  modalSize = 'md',
  showConfirmBtnSpinner = false,
  disableConfirmBtn = false,
  showConfirmModal,
  bodyStyle = 'px-4 text-center'
}) => {
  return (
    <Modal size={modalSize} isOpen={showConfirmModal} centered={true}>
      <ModalHeader toggle={handleCancel}>
        <i className="fas fa-exclamation-circle mr-2 text-warning"></i>
        {header}
      </ModalHeader>
      <ModalBody className={bodyStyle}>
        <Row>
          <Col>{children}</Col>
        </Row>
      </ModalBody>
      <ModalFooter className="card-footer">
        <Button
          color={confirmBtnColor}
          onClick={handleConfirm}
          disabled={showConfirmBtnSpinner || disableConfirmBtn}
        >
          {confirmBtnIcon &&
            confirmBtnIcon.trim() !== '' &&
            !showConfirmBtnSpinner && (
              <i className={`${confirmBtnIcon} mr-2`}></i>
            )}
          {showConfirmBtnSpinner && (
            <i className="fas fa-spinner fa-spin mr-2"></i>
          )}
          {showConfirmBtnSpinner ? 'Wait...' : confirmBtnText}
        </Button>
        <Button onClick={handleCancel} color="secondary">
          <i className="fas fa-times mr-2"></i>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};
export default ConfirmModal;
