import React from 'react';
import { KoncertLogodiv, ErrorLinks } from '@koncert/shared-components';

const InactiveUserMessage = () => (
  <p>You have been marked as inactive. Please contact your Admin.</p>
);

const NoLicenseUserMessage = () => (
  <p>You don't have Cadence license. Please contact your Admin.</p>
);

const Error500 = (props) => {
  const { isInactiveUser, isNoLicenseUser } = props;

  return (
    <div className="d-flex flex-column justify-content-center min-vh-100">
      <div className="text-center mb-4">
        <div className="mb-3">
          <KoncertLogodiv customWidth="350" />
        </div>
        <div className="text-lg mb-3">
          <i className="fa fa-wrench text-muted mr-2"></i>
          500
        </div>
        {isInactiveUser ? (
          <InactiveUserMessage />
        ) : isNoLicenseUser ? (
          <NoLicenseUserMessage />
        ) : (
          <>
            <p className="lead m-0">Oh! Something went wrong :(</p>
            <p>Don't worry, we're now checking this.</p>
            <p>
              In the meantime, please try one of those links below or come back
              in a moment
            </p>
          </>
        )}
      </div>
      <ErrorLinks props={props} gotoApp={!isInactiveUser && !isNoLicenseUser} />
    </div>
  );
};

export default Error500;
