/**
 * @author @rajesh-thiyagarajan
 * @version V11.0
 */
import React, { useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  ListGroup,
  ListGroupItem,
  Tooltip,
  UncontrolledDropdown,
} from 'reactstrap';
import { useAuth0 } from '@auth0/auth0-react';
import { toggleSetting } from '../../store/actions';
import Avatar from '../../images/default-avatar.jpg';
import UserContext from '../../UserContext';
import { handleLogout } from '../../util';
import PageLoader from '../Common/PageLoader';

const Header = () => {
  const dispatch = useDispatch();

  //useContext declarations
  const { user, loading: userLoading } = useContext(UserContext);

  //useState declarations
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggleTooltip = () => setTooltipOpen(!tooltipOpen);

  //to get current logged in user details
  const { user: currentAuth0User, logout } = useAuth0();

  if (userLoading) {
    return <PageLoader />;
  }

  const _avatar = currentAuth0User
    ? currentAuth0User.picture || Avatar
    : Avatar;

  //desktop or window collapse button
  const toggleCollapsed = (e: React.MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    dispatch(toggleSetting('isCollapsed'));
    dispatch(toggleSetting('isCollapsedText'));
    resize();
  };

  const resize = () => {
    const evt = document.createEvent('UIEvents');
    evt.initEvent('resize', true, false);
    window.dispatchEvent(evt);
  };

  return (
    <header className="topnavbar-wrapper">
      {/* START Top Navbar */}
      <nav className="navbar topnavbar">
        {/* START navbar header */}
        <div className="navbar-header">
          <div className="d-flex align-items-center pl-4">
            <a className="text-decoration-none navbar-brand text-left" href="/">
              <div className="d-flex align-items-center py-1">
                <i className="fas fa-stream mr-2 text-white"></i>
                <span className="text-sans-serif text-color-koncert-white h4 mb-0">
                  License
                </span>
              </div>
            </a>
          </div>
        </div>
        {/* END navbar header */}
        {/* START Left navbar */}
        <ul className="navbar-nav mr-auto flex-row">
          <li className="nav-item">
            {/* Button used to collapse the left sidebar. Only visible on tablet and desktops */}
            <span
              className="nav-link d-none d-md-block d-lg-block d-xl-block pointer"
              onClick={toggleCollapsed}
            >
              <em className="fas fa-bars"></em>
            </span>
            {/* Button to show/hide the sidebar on mobile. Visible on mobile only. */}
            <span className="nav-link sidebar-toggle d-md-none pointer">
              <em className="fas fa-bars"></em>
            </span>
          </li>
          <li className="nav-item">
            {/* Button used to collapse the left sidebar. Only visible on tablet and desktops */}
            <span className="nav-link d-none d-md-block d-lg-block d-xl-block pointer">
              <em className="far fa-bell"></em>
            </span>
          </li>
        </ul>
        <ul className="navbar-nav flex-row">
          <UncontrolledDropdown
            nav
            inNavbar
            className="dropdown-list d-flex align-items-center"
          >
            <DropdownToggle nav className="dropdown-toggle-nocaret p-2 pr-3">
              <img
                className="rounded-circle"
                src={_avatar}
                alt="Avatar"
                height="30"
                id="TooltipUser"
                onClick={toggleTooltip}
              />
              <Tooltip
                placement="bottom"
                isOpen={tooltipOpen}
                target="TooltipUser"
                toggle={toggleTooltip}
              >
                <div className="text-light">{currentAuth0User.name}</div>
                <div className="text-muted">{currentAuth0User.email}</div>
              </Tooltip>
            </DropdownToggle>
            <DropdownMenu className="animated flipInX p-1" right>
              <DropdownItem className="p-0">
                {/* START list group */}
                <ListGroup>
                  <ListGroupItem
                    className="py-0"
                    action
                    tag="a"
                    href="/user/settings"
                    onClick={(e: React.MouseEvent) => e.preventDefault()}
                  >
                    <div>
                      <div className="item user-block pt-2 pb-0">
                        {/* User picture */}
                        <div className="user-block-picture">
                          <div className="user-block-status">
                            <img
                              className="img-thumbnail rounded-circle"
                              src={_avatar}
                              alt="Avatar"
                              width="60"
                              height="60"
                            />
                            <div className="circle bg-success circle-lg"></div>
                          </div>
                        </div>
                        {/* Name and Job */}
                        <div className="user-block-info pt-1 pb-2">
                          <div className="mb-1">Hello, {user?.username}</div>
                        </div>
                      </div>
                    </div>
                  </ListGroupItem>
                  <ListGroupItem
                    action
                    onClick={() => handleLogout(dispatch, logout)}
                  >
                    <span className="d-flex align-items-center justify-content-center">
                      <span className="text-sm">
                        <i className="fas fa-sign-out-alt mr-1"></i>Logout
                      </span>
                    </span>
                  </ListGroupItem>
                </ListGroup>
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </ul>
      </nav>
      {/* END Top Navbar */}
    </header>
  );
};
export default Header;
