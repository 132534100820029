/**
 * @author @rajesh-thiyagarajan
 * @version V11.0
 */
import React, { useContext } from 'react';
import { Redirect, Route } from 'react-router-dom';
import UserContext from './UserContext';

const ProtectedRoute = (props: any) => {
  const { user, loading: userLoading } = useContext(UserContext);

  if (userLoading) return null;

  if (!user) return <Redirect to="/error500" />;

  const roles = user?.user?.role || [];

  const { component: Component, requireAdmin, computedMatch, ...rest } = props;

  if (
    (props.path === '/settings' &&
      (roles.indexOf('ADM') !== -1 || roles.indexOf('CMT') !== -1)) ||
    (props.path === '/reports' &&
      (roles.indexOf('ADM') !== -1 ||
        roles.indexOf('CMT') !== -1 ||
        roles.indexOf('FT') !== -1))
  ) {
    return null;
  }

  if (user.rolesMask !== 1 && requireAdmin === true)
    return <Redirect to="/error500" />;
  return <Route component={Component} {...rest} />;
};

export default ProtectedRoute;
