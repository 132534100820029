import { GET_ALL_ROLES, RESET_ALL_ROLES, SET_ALL_ROLES } from '../actions';

const rolesReducer = (state = { fetchedAll: false, data: [] }, action: any) => {
  switch (action.type) {
    case GET_ALL_ROLES:
      return {
        ...state,
        ...action.payLoad,
      };
    case SET_ALL_ROLES:
      return {
        ...state,
        ...action.payLoad,
      };
    case RESET_ALL_ROLES:
      return {
        ...state,
        ...action.payLoad,
      };
    default:
      return state;
  }
};

export default rolesReducer;
