/**
 * @author @rajesh-thiyagarajan
 * @version V11.0
 */
import React from 'react';

const Footer = ({ productName }: { productName: string }) => {
  const year = new Date().getFullYear();
  return (
    <footer className="footer-container">
      <span>
        &copy; {year} - {productName}
      </span>
    </footer>
  );
};
export default Footer;
