import React, { useEffect, useRef } from 'react';
import { toast } from 'react-toastify';
import moment from 'moment';
import { parseUrl } from 'query-string';

import * as actions from '../../app/store/actions';

toast.configure();

export const notify = (
  message: string | JSX.Element,
  ToasterType: 'info' | 'success' | 'warning' | 'error' | 'default' = 'error',
  id?: string
) => {
  const toastId = id ? id : message;
  const position:
    | 'top-right'
    | 'top-center'
    | 'top-left'
    | 'bottom-right'
    | 'bottom-center'
    | 'bottom-left' = 'top-right';
  const options: any = {
    type: ToasterType,
    position: position,
    toastId: toastId.toString(),
    pauseOnFocusLoss: false,
    pauseOnHover: true,
  };
  if (ToasterType === 'error') {
    options['autoClose'] = false;
  }

  const activeToastId = toast(message, options);
  if (toast.isActive(activeToastId)) {
    toast.update(toastId.toString(), {
      render: message,
      type: ToasterType,
      autoClose: ToasterType === 'error' ? null : false,
    });
  } else {
    toast(message, options);
  }
};

/* eslint-disable no-useless-escape */
export const isEmail = (email: string): boolean => {
  return /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/.test(
    email
  );
};
export const PUBLIC_PAGES = [
  '/logout',
  '/register',
  '/recover',
  '/lock',
  '/notfound',
  '/error500',
  '/maintenance',
  '/reset_password',
  '/confirmation',
  '/easuccess.action',
];

export const clearBrowserCache = (dispatch: any) => {
  sessionStorage.removeItem('sessionId');
  dispatch(actions.resetRoles());
};

export const handleLogout = (
  dispatch: any,
  logout: any,
  returnToLogin?: boolean
) => {
  clearBrowserCache(dispatch);
  logout({
    returnTo: `${window.location.origin}${returnToLogin ? '' : '/logout'}`,
  });
};

export const useClickOutside = (handler: any, isActive: boolean) => {
  const domNode = useRef(null);

  useEffect(() => {
    const clickHandler = (e: any) => {
      // @ts-ignore:
      if (isActive && !domNode.current.contains(e.target)) {
        handler();
      }
    };
    document.addEventListener('mousedown', clickHandler);
    return () => {
      document.removeEventListener('mousedown', clickHandler);
    };
  });
  return domNode;
};

export const formateDateTime = (inputDateString: string) => {
  if (inputDateString) {
    return moment(inputDateString).format('M/D/YYYY h:mm A');
  }
  return '';
};

export const getErrorMessage = (response: any, defaultMessage: string) => {
  return response.graphQLErrors[0]
    ? response.graphQLErrors[0].message
    : defaultMessage;
};

export const copyToClipboard = (text: string) => {
  navigator.clipboard.writeText(text).then(
    function () {
      notify('Request Id has been copied to clipboard!', 'success');
    },
    function (err) {
      notify('Could not copy Request Id');
    }
  );
};
// below function is to show success message if the prospect action is done using job queue
export const showSuccessMsg = (requestId: string): void => {
  const successMessage = `Your request has been submitted.`;
  const successFormat = (
    <>
      <h6>{successMessage}</h6>
      {requestId && (
        <>
          <br />
          <span>RequestId: {requestId}</span>
          <i
            className="fas fa-copy ml-2 text-light"
            title="Copy Request ID"
            onClick={() => copyToClipboard(requestId)}
          ></i>
        </>
      )}
    </>
  );
  return notify(successFormat, 'success', 'prospect_action_success');
};

export const showErrorMessage = (
  response: any,
  defaultMessage: string,
  errorData: any,
  id?: string
): void => {
  let errorMsg =
    response.graphQLErrors && response.graphQLErrors.length > 0
      ? response.graphQLErrors[0].message === 'shared user ids are  mandatory.'
        ? 'User names are mandatory'
        : response.graphQLErrors[0].message
      : response.networkError
      ? response.networkError.message
      : defaultMessage;
  if (errorMsg === 'Timeout exceeded') {
    errorMsg = 'Failed to fetch data';
  }

  const requestId = errorData?.requestId;
  const errorFormat = (
    <>
      <h6>{errorMsg}</h6>
      {requestId && (
        <>
          <br />
          <span>RequestId: {requestId}</span>
          <i
            className="fas fa-copy ml-2 text-grey"
            title="Copy Request ID"
            onClick={() => copyToClipboard(requestId)}
          ></i>
        </>
      )}
    </>
  );
  return notify(errorFormat, 'error', id);
};

export const trimValue = (value: string) => {
  return value && value !== 'null'
    ? typeof value === 'string'
      ? value.trim()
      : value
    : '';
};

export const getDueDate = (date: string) => {
  let result = '';
  const array = trimValue(date).split(' ');
  for (let index = 0; index < array.length; index++) {
    const value = array[index];
    if (index % 2 === 0 && parseInt(value) > 0) {
      result += ` ${value} ${trimValue(array[index + 1]).toLowerCase()}`;
    }
  }
  result = result.trim().split(' ').slice(0, 4).join(' ');
  return result;
};

export const isValidURL = (textValue: string) => {
  if (textValue === undefined || textValue === null || textValue === '') {
    return false;
  }
  textValue = '' + textValue;

  const validUrl =
    textValue.indexOf('http://') === 0 ||
    textValue.indexOf('https://') === 0 ||
    textValue.indexOf('www.') === 0;
  return validUrl;
};

export const formatWebLink = (fieldValue: string) => {
  const isValidUrl = isValidURL(fieldValue);

  if (isValidUrl) {
    fieldValue = fieldValue.startsWith('http')
      ? fieldValue
      : 'https://' + fieldValue;
  }
  return fieldValue;
};

export const timeLeft = (moment: any, date: string) => {
  const now = moment(new Date());
  const futureDate = moment(new Date(date));
  const diffDuration = moment.duration(futureDate.diff(now));
  let result = '';
  if (futureDate.diff(now, 'years') > 0) {
    result +=
      futureDate.diff(now, 'years') +
      (futureDate.diff(now, 'years') === 1 ? ' year ' : ' years ');
  }
  if (
    futureDate.diff(
      moment().add(futureDate.diff(now, 'years'), 'years'),
      'days'
    ) > 0
  ) {
    const days = futureDate.diff(
      moment().add(futureDate.diff(now, 'years'), 'years'),
      'days'
    );

    result += days + (days === 1 ? ' day ' : ' days ');
  }
  if (diffDuration.hours() > 0) {
    result +=
      diffDuration.hours() +
      (diffDuration.hours() === 1 ? ' hour ' : ' hours ');
  }
  if (diffDuration.minutes() > 0) {
    result +=
      diffDuration.minutes() +
      (diffDuration.minutes() === 1 ? ' min ' : ' mins ');
  }
  if (diffDuration.seconds() > 0) {
    result +=
      diffDuration.seconds() +
      (diffDuration.seconds() === 1 ? ' sec ' : ' secs ');
  }

  return `${result.trim()}`;
};

export const convertDateFormat = (data: any) => {
  if (data) {
    const date = data.split('-');
    return date[1] + '/' + date[2] + '/' + date[0];
  } else {
    return '';
  }
};

export const diffMonths = (startDate: string, endDate: string): number => {
  if (startDate && endDate) {
    const a = moment(endDate);
    const b = moment(startDate);

    return a.diff(b, 'months', true);
  } else {
    return 0;
  }
};

export const diffDays = (startDate: string, endDate: string): number => {
  if (startDate && endDate) {
    return moment(new Date(convertDateFormat(endDate))).diff(
      new Date(convertDateFormat(startDate)),
      'days',
      true
    );
  } else {
    return 0;
  }
};

export const isNumber = (
  value: string | null | undefined | number
): boolean => {
  if (value === null || value === undefined) {
    return false;
  }
  return /^\d+$/.test(value + '');
};

export const isValidDate = (value: string): boolean => {
  if (value === null || value === undefined) {
    return false;
  }
  const values = value.split('-');
  return values[0].length <= 4;
};

export const isValidStartDate = (value: string): boolean => {
  if (value === null || value === undefined) {
    return false;
  }
  const values = value.split('-');
  return parseInt(values[0]) >= new Date().getUTCFullYear();
};

export const isGreaterThan = (date1: string, date2: string): boolean => {
  if (
    date1 === null ||
    date1 === undefined ||
    date2 === null ||
    date2 === undefined
  ) {
    return false;
  }
  return new Date(date1).getTime() > new Date(date2).getTime();
};

export const isLessThan = (date1: string, date2: string): boolean => {
  if (
    date1 === null ||
    date1 === undefined ||
    date2 === null ||
    date2 === undefined
  ) {
    return false;
  }
  return new Date(date1).getTime() < new Date(date2).getTime();
};

export const FAILED_TO_FETCH_DATA =
  'Sorry! Failed to fetch data. Please try again.';
export const NO_DATA_AVAILABLE = 'No data available.';
export const PLEASE_CONTACT_CONNECTLEADER_SUPPORT =
  'Please contact Koncert Support';
export const SORRY_NO_DATA_AVAILABLE = 'Sorry! No data available.';

export default getErrorMessage;

export const handleEditOrder = (history: any, location: any) => {
  // used in view invoice/ final order completion page so move here as it's common place
  if (
    window.confirm(
      'Already Verified invoice(s) will be cleared. Do you wish to continue?'
    )
  ) {
    const { query } = parseUrl(window.location.search);
    //@ts-ignore
    query['filter[activeTab]'] = 1;

    const searchString = Object.entries(query)
      .map(([key, val]) => `${key}=${val}`)
      .join('&');

    history.push(`${location.pathname}?${searchString}`);
  }
};

/*
 This is just triggr production build -- Ravi
 Trigger netlify production build.
*/
