/**
 * @author @rajesh-thiyagarajan
 * @version V11.0
 */
import React, { ReactNode } from 'react';
import { useQuery } from '@apollo/react-hooks';

import { useDispatch } from 'react-redux';
import { signInUser } from './store/actions';
import CURRENT_USER_QUERY from './query/CurrentUserQry';
import PageLoader from './components/Common/PageLoader';
import { ApolloError } from 'apollo-client';

interface UserContextInterface {
  user?: any;
  loading: boolean;
  error: ApolloError | undefined;
  refetch?: any;
  hasEditAccess?: boolean;
}

const UserContext = React.createContext({} as UserContextInterface);

export const UserProvider = ({ children }: { children: ReactNode }) => {
  //fetch the current user
  const { data, loading, error, refetch } = useQuery(CURRENT_USER_QUERY);

  const dispatch = useDispatch();

  dispatch(signInUser('token', loading || error ? null : data.me.data[0]));

  // if (
  //   error?.message === 'GraphQL error: Invalid License' ||
  //   (!loading &&
  //     !error &&
  //     !data?.me?.data[0]?.products.split(',').includes('TC'))
  // ) {
  //   throw new Error('Invalid license');
  // }

  if (loading) {
    return (
      <UserContext.Provider value={{ error, loading }}>
        <PageLoader />
      </UserContext.Provider>
    );
  }

  const user = !error ? data.me.data[0] : null;

  const roles = user?.role || [];
  const hasEditAccess = roles.indexOf('ADM') > -1 || roles.indexOf('CMT') > -1;

  return (
    <UserContext.Provider
      value={{ user, loading, error, refetch, hasEditAccess }}
    >
      {!loading && children}
    </UserContext.Provider>
  );
};
export const UserConsumer = UserContext.Consumer;

export default UserContext;
