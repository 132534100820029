import { combineReducers } from 'redux';

import settingsReducer from './settings.reducer';
import themesReducer from './themes.reducer';
import authReducer from './auth.reducers';
import rolesReducer from './roles.reducers';

export default combineReducers({
  settings: settingsReducer,
  theme: themesReducer,
  auth: authReducer,
  roles: rolesReducer,
});
