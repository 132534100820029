/**
 * @author @rajesh-thiyagarajan
 * @version V11.0
 */
import React, { Suspense, lazy } from 'react';
import { withRouter, Switch, Route, Redirect } from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import PageLoader from './components/Common/PageLoader';
import Base from './components/Layout/Base';
import { BasePage } from './components/Layout/BasePage';
import ProtectedRoute from './ProtectedRoute';
import { UserProvider } from './UserContext';
import { PUBLIC_PAGES } from './util';

import { Error500 } from '@koncert/shared-components';

//order creation page
const OrderProcess = lazy(() =>
  import('./components/Pages/OrderCreation/OrderProcess')
);
//orders page
const Orders = lazy(() => import('./components/Pages/Orders/Orders'));
//logout page
const Logout = lazy(() => import('./components/Common/Logout'));
//Clients page
const Clients = lazy(() => import('./components/Pages/Clients/Clients'));
//Clients Users page
const ClientUsers = lazy(() => import('./components/Pages/ClientUsers/Users'));
//View client page
const ViewClient = lazy(() => import('./components/Pages/Clients/ViewClient'));
//invoices page
const Invoices = lazy(() => import('./components/Pages/Invoices/Invoices'));
//invoices page
const ViewInvoice = lazy(() =>
  import('./components/Pages/Invoices/ViewInvoice')
);
//audit trail page
const AuditTrail = lazy(() =>
  import('./components/Pages/Settings/AuditTrail/AuditTrail')
);
//reports page
const Reports = lazy(() => import('./components/Pages/Reports/Reports'));
//settings page
const Settings = lazy(() => import('./components/Pages/Settings/Settings'));

const UserAssignment = lazy(() =>
  import('./components/Pages/UserAssignment/UserAssignment')
);

const Routes = ({ location }: { location: any }) => {
  const currentKey = location.pathname.split('/')[1] || '/';
  const timeout = { enter: 500, exit: 500 };

  const animationName = 'rag-fadeIn';

  if (PUBLIC_PAGES.indexOf(location.pathname) > -1) {
    return (
      <BasePage>
        <Suspense fallback={<PageLoader />}>
          <Switch location={location}>
            <Route path="/logout" component={Logout} />
            <Route path="/error500" component={Error500} />
          </Switch>
        </Suspense>
      </BasePage>
    );
  }

  return (
    <UserProvider>
      <Base productName="Koncert">
        <TransitionGroup>
          <CSSTransition
            key={currentKey}
            timeout={timeout}
            classNames={animationName}
            exit={false}
          >
            <div>
              <Suspense fallback={<PageLoader />}>
                <Switch location={location}>
                  <Redirect exact from="/" to="/clients" />
                  <ProtectedRoute path="/clients" exact component={Clients} />
                  <ProtectedRoute
                    path="/clients/:id"
                    exact
                    component={ViewClient}
                  />
                  <ProtectedRoute
                    path="/client-users"
                    exact
                    component={ClientUsers}
                  />
                  <ProtectedRoute path="/orders" exact component={Orders} />
                  <ProtectedRoute
                    path={['/orders/create', '/orders/:id']}
                    exact
                    component={OrderProcess}
                  />
                  <ProtectedRoute path="/invoices" exact component={Invoices} />
                  <ProtectedRoute
                    path="/audit-trail"
                    exact
                    component={AuditTrail}
                  />
                  <ProtectedRoute path="/reports" exact component={Reports} />
                  <ProtectedRoute path="/settings" exact component={Settings} />
                  <ProtectedRoute path="/settings/:tab" component={Settings} />
                  <ProtectedRoute
                    path="/clients/:orgId/user-assignment"
                    exact
                    component={UserAssignment}
                  />
                  <ProtectedRoute
                    path="/invoices/:id"
                    exact
                    component={ViewInvoice}
                  />
                </Switch>
              </Suspense>
            </div>
          </CSSTransition>
        </TransitionGroup>
      </Base>
    </UserProvider>
  );
};
export default withRouter(Routes);
