/**
 * @author @rajesh-thiyagarajan
 * @version V11.0
 */
import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import reducers from './reducers';
import middlewares from './middlewares';

import { updateTheme } from './middlewares/themes.middleware';

import { persistedState, saveState } from './persisted.store';

export default function configureStore() {
  const store = createStore(
    reducers,
    persistedState, // second argument overrides the initial state
    applyMiddleware(thunk, ...middlewares)
  );

  // add a listener that will be invoked on any state change
  store.subscribe(() => {
    saveState(store.getState());
  });
  // Update the initial theme
  updateTheme(store.getState());

  return store;
}
